import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.auth.isSigned);

    if (isAuthenticated) {
        return <>
            {/* <TutorialHelper ></TutorialHelper> */}
            {children}
        </>
    }
    return <Navigate to="/sign-in" />;
}

export default PrivateRoute