import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '../../hooks/useAxios';
import routes from '../../router/routess';
import { signOut } from '../../store/auth';
import classes from './Sidebar.module.css';

const Sidebar = ({ collapsed, setCollapsed, location }) => {
  const { data, loading, refetch } = useLazyQuery('/business/settings');
  useEffect(() => {
    const refetchSettings = async () => {
      try {
        const response = await refetch();
        console.log(response, 'data1234');
      } catch (error) {
        console.error('Error fetching screens:', error);
      }
    };
    refetchSettings();
  }, [refetch]);
  const dispatch = useDispatch();
  const handleSignOut = () => {
    // #todo signOut fonksiyonunu buraya ekleyin
    dispatch(signOut());
  };
  const { screen_permissions } = useSelector((state) => state.auth);
  console.log(screen_permissions, 'screens');

  const menuItems = routes
    .filter(
      (route) => route.sidebar

      // #todo backende screenler eklenince burası yorumdan cıkacak
      // && screen_permissions?.find(e => e.screen_name === route.name + '_screen' && e.permissions.includes('preview'))
    )
    .map((route) => {
      return {
        key: route.path,
        icon: location.pathname === route.path && route.activeIcon ? route.activeIcon : route.icon || <UserOutlined />,
        label: <Link to={route.path}>{route.title}</Link>,
      };
    });

  const items = [
    ...menuItems,
    {
      type: 'divider',
      style: { margin: 0, border: 0, flexGrow: 1, visibility: 'hidden' },
    },
    // {
    //     key: '/logout', label: <Link onClick={
    //         handleSignOut
    //     }>
    //         Sign Out
    //     </Link>, icon: <UserOutlined />
    // }
  ];

  const DemoCard = () => (
    <div className={classes.democard_container}>
      <div className={classes.democard_desc}>
        <span>Yeni</span>
        <span>Bordro</span>
        <span>Entegrasyonu</span>
        <button className={classes.democard_btn}>DEMOYU İNCELE</button>
      </div>
    </div>
  );

  return (
    <Sider
      className={classes.root}
      theme="light"
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
      breakpoint="md"
      collapsed={collapsed}
      onBreakpoint={(e) => setCollapsed(e)}
      trigger={
        <Button
          type="primary"
          icon={<LogoutOutlined />}
          style={{
            width: '100%',
            borderRadius: 0,
            marginTop: 'auto',
          }}
          // Sign out işlevini buraya ekleyin
          onClick={handleSignOut}
        >
          {!collapsed && 'Sign Out'}
        </Button>
      }
    >
      <div
        style={{
          height: '100vh',
        }}
      >
        {data?.data?.logo ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90px' }}>
            {/* <span>HR</span> */}
            <Link to={'/'}>
              <img src={`https://node207217-hr-skript.de1.mageteknik.com/${data?.data?.logo}`} width="70px" />
            </Link>
          </div>
        ) : (
          <div
            style={{
              height: 90,
              // background: 'rgb(255 0 0 / 50%)',
              margin: '0 16px',
              borderRadius: '4px',
              width: 'calc(100% - 32px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Logo
          </div>
        )}

        <div
          style={{
            height: 'calc(100vh - 90px)',
            backgroundColor: '#755271',
          }}
        >
          <Menu
            className={classes.layout_sidebar}
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              paddingBlockEnd: '1rem',
            }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={location.pathname}
            items={items?.slice(0, 8)}
          />
          <DemoCard />

          <Menu
            className={classes.layout_sidebar}
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              paddingBlockEnd: '1rem',
            }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={location.pathname}
            items={items?.slice(8)}
          />
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
