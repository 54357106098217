import { HomeOutlined, UserAddOutlined, } from '@ant-design/icons';
import { Button, Result } from 'antd';
import { lazy } from 'react';
import { Trans } from 'react-i18next';
import { FaCircle } from 'react-icons/fa';
import { RiTeamFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const Bonus = lazy(() => import('../pages/Bonus'))
const Branches = lazy(() => import('../pages/Branches'))
const Dashboard = lazy(() => import('../pages/Dashboard'))
const Departments = lazy(() => import('../pages/Departments'))
const Events = lazy(() => import('../pages/Events/Events'))
const Expenses = lazy(() => import('../pages/Expenses'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))
const Hierarchy = lazy(() => import('../pages/Hierarchy/Hiearchy'))
const Jobs = lazy(() => import('../pages/Jobs'))
const TeamLeaders = lazy(() => import('../pages/Leaders'))
const Leaves = lazy(() => import('../pages/Leaves'))
const Projects = lazy(() => import('../pages/Projects'))
const Register = lazy(() => import('../pages/Register'))
const Reports = lazy(() => import('../pages/Reports'))
const ResetPassword = lazy(() => import('../pages/ResetPassword'))
const Roles = lazy(() => import('../pages/Roles'))
const Salaries = lazy(() => import('../pages/Salaries'))
const BirthdaySetting = lazy(() => import('../pages/Settings/BirtdaySettings'))
const CompanyInfo = lazy(() => import('../pages/Settings/CompanyInfo'))
const DepartureReason = lazy(() => import('../pages/Settings/DepartureReason'))
const ExpenseSettings = lazy(() => import('../pages/Settings/ExpenseSettings'))
const LeaveSettings = lazy(() => import('../pages/Settings/LeaveSettings'))
const Rules = lazy(() => import('../pages/Settings/Rules'))
const Settings = lazy(() => import('../pages/Settings/Settings'))
const SubscriptionInfo = lazy(() => import('../pages/Settings/SubscriptionInfo/SubscriptionInfo '))
const SystemSettings = lazy(() => import('../pages/Settings/SystemSettings'))
const SignIn = lazy(() => import('../pages/SignIn'))
const Skills = lazy(() => import('../pages/Skills'))
const Team = lazy(() => import('../pages/Team/Team'))
const Teams = lazy(() => import('../pages/Teams'))
const Titles = lazy(() => import('../pages/Titles'))
const Worker = lazy(() => import('../pages/Worker'))
const Workers = lazy(() => import('../pages/Workers'))
const Demo = lazy(() => import('../pages/Demo/Demo'))
const TeamMembers = lazy(() => import('../pages/Members'))
const Members = lazy(() => import('../pages/Members'))
const routes = [
  {
    name: 'Dashboard',
    path: '/',
    element: <Dashboard />,
    sidebar: true,
    title: <Trans i18nKey="Dashboard.title" />,
    icon: <HomeOutlined style={{ fontSize: '16px' }} />,
  },
  {
    name: 'Sign In',
    path: '/sign-in',
    element: <SignIn />,
    auth: false,
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    element: <ForgotPassword />,
    auth: false,
  },
  {
    name: 'Reset Password',
    path: '/auth/reset-password/:token',
    element: <ResetPassword />,
    auth: false,
  },
  {
    name: 'users',
    path: '/workers',
    element: <Workers />,
    sidebar: true,
    title: <Trans i18nKey="Workers.title" />,
    icon: <RiTeamFill style={{ fontSize: '16px' }} />,
  },
  // {
  //   name: 'departments',
  //   path: '/departments',
  //   element: <Departments />,
  //   sidebar: true,
  //   title: <Trans i18nKey="Departments.title" />,
  // },
  // {
  //   name: 'branches',
  //   path: '/branches',
  //   element: <Branches />,
  //   sidebar: true,
  //   title: <Trans i18nKey="Branches.title" />,
  // },
  // {
  //   name: 'titles',
  //   path: '/titles',
  //   element: <Titles />,
  //   sidebar: true,
  //   title: <Trans i18nKey="Titles.title" />,
  // },
  {
    name: 'atildin',
    path: '/atildin',
    element: <> atıldın </>,
  },
  {
    name: 'leaves',
    path: '/leaves',
    element: <Leaves />,
    sidebar: true,
    title: <Trans i18nKey="Leaves.title" />,
    icon: <UserAddOutlined style={{ fontSize: '16px' }} />,
  },
  {
    name: 'Expenses',
    path: '/expenses',
    element: <Expenses />,
    sidebar: true,
    title: <Trans i18nKey="Expenses.title" />,
    icon: <FaCircle style={{ fontSize: '16px', opacity: '0.8' }} />,
  },
  {
    name: 'Bonus',
    path: '/bonus',
    element: <Bonus />,
    sidebar: true,
    title: 'Avanslar',
    icon: <FaCircle style={{ fontSize: '16px', opacity: '0.8' }} />,
    // icon: <MoneyCollectOutlined />,
    // activeIcon: <MoneyCollectFilled />,
  },
  {
    name: 'Salaries',
    path: '/salaries',
    element: <Salaries />,
    sidebar: true,
    title: <Trans i18nKey="Salaries.title" />,
    icon: <FaCircle style={{ fontSize: '16px', opacity: '0.8' }} />,
  },
  {
    name: 'Teams',
    path: '/teams',
    element: <Teams />,
    sidebar: false,
    title: 'Teams',
  },
  {
    name: 'Team',
    path: '/team/:id',
    element: <Team />,
  },
  {
    name: 'Team Leaders',
    path: '/team-leaders',
    element: <TeamLeaders />,
    sidebar: false,
    title: 'Team Leaders',
  },
  {
    name: 'Projects',
    path: '/projects',
    element: <Projects />,
    sidebar: false,
    title: 'Projects',
  },
  {
    name: 'Skills',
    path: '/skills',
    element: <Skills />,
    sidebar: false,
    title: 'Skills',
  },
  {
    name: 'Team Members',
    path: '/team-members',
    element: <TeamMembers />,
    sidebar: false,
    title: 'Team Members',
  },
  {
    name: 'Jobs',
    path: '/jobs',
    element: <Jobs />,
    sidebar: false,
    title: 'Jobs',
  },
  {
    name: 'Members',
    path: '/members',
    element: <Members />,
    sidebar: false,
    title: 'Members',
  },
  {
    name: 'Worker',
    path: '/worker/:id',
    element: <Worker />,
  },
  {
    name: '404',
    path: '*',
    element: (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button component type="primary">
              Back Home
            </Button>
          </Link>
        }
      />
    ),
    auth: false,
  },
  {
    name: 'reports',
    path: '/reports',
    element: <Reports />,
    sidebar: true,
    title: <Trans i18nKey="Reports.title" />,
    icon: <FaCircle style={{ fontSize: '16px', opacity: '0.8' }} />,
  },
  {
    name: 'reports',
    path: '/reports/:id',
    element: <Reports />,
  },
  {
    name: 'Events',
    path: '/events',
    element: <Events />,
    sidebar: true,
    title: <Trans i18nKey="Events.title" />,
    icon: <FaCircle style={{ fontSize: '16px', opacity: '0.8' }} />,
  },
  {},

  {
    name: 'Settings',
    path: '/settings',
    element: <Settings />,
    sidebar: true,
    title: <Trans i18nKey="Settings.title" />,
    icon: <FaCircle style={{ fontSize: '16px', opacity: '0.8' }} />,
    children: [
      {
        name: 'Company Info',
        path: 'company-info',
        element: <CompanyInfo />,
      },
      {
        name: 'Rules',
        path: 'rules',
        element: <Rules />,
      },
      {
        name: 'Subscription Info',
        path: 'subscription-info',
        element: <SubscriptionInfo />,
      },
      // {
      //   name: 'Expense Settings',
      //   path: 'expense-settings',
      //   element: <ExpenseSettings />,
      // },
      {
        name: 'Departure Reason',
        path: 'system-settings/departure-reason',
        element: <DepartureReason />,
      },
      {
        name: 'Departments',
        path: 'departments',
        element: <Departments />,
      },
      {
        name: 'branches',
        path: 'branches',
        element: <Branches />,
      },
      {
        name: 'titles',
        path: 'titles',
        element: <Titles />,
      },
      {
        name: 'roles',
        path: 'roles',
        element: <Roles />,
      },
      {
        name: 'System Settings',
        path: 'system-settings',
        element: <SystemSettings />,
      },
      {
        name: 'Leave Settings',
        path: 'system-settings/leave-settings',
        element: <LeaveSettings />,
      },
      {
        name: 'Expense Settings',
        path: 'system-settings/expense-settings',
        element: <ExpenseSettings />,
      },
      {
        name: 'Birthday Settings',
        path: 'system-settings/birthday-settings',
        element: <BirthdaySetting />,
      },
    ],
  },

  {
    name: 'Register',
    path: '/register',
    element: <Register />,
    sidebar: false,
    title: <Trans i18nKey="Register" />,
    auth: false,
  },
  {
    name: 'Hierarchy',
    path: '/hierarchy',
    element: <Hierarchy />,
    sidebar: true,
    title: 'Hierarchy',
    icon: <FaCircle style={{ fontSize: '16px', opacity: '0.8' }} />,
  },
];

export default routes;
