import { ConfigProvider } from 'antd';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import router from './router/routes';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#755271',
        },
      }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider fallbackElement={<div>Gitti...</div>} router={router}></RouterProvider>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
