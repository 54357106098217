import { Layout, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import MainContext from './MainContext';
import './MainLayout.css';

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} location={location} />
      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 80 : 316,
          transition: 'all 0.2s',
        }}
      >
        <MainContext.Provider value={{ collapsed, setCollapsed }}>
          <Content
            style={{
              padding: 20,
              paddingTop: 106,
            }}
          >
            {children}
          </Content>
        </MainContext.Provider>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
