import axios from 'axios';
import i18next from 'i18next';
import { setRefreshToken, setToken, signOut } from './store/auth';
import store from './store/store';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const token = store.getState().auth.token;

const axiosInstance = axios.create({
    baseURL: 'https://node207217-hr-skript.de1.mageteknik.com/api', // API'nizin base URL'sini buraya koyun
    headers: {
        'Content-Type': 'application/json',
        Authorization: token && ('Bearer ' + token),
        language: i18next.language || 'tr'
    }
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = store.getState().auth.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers.language = i18next.language || 'tr';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response.status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                const refreshToken = store.getState().auth.refreshToken;
                if (refreshToken)
                    axiosInstance.post('/auth/refresh-token', { refreshToken })
                        .then(({ data }) => {
                            store.dispatch(setToken(data.accessToken));
                            store.dispatch(setRefreshToken(data.refreshToken));
                            axiosInstance.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
                            processQueue(null, data.accessToken);
                        })
                        .catch((refreshError) => {
                            store.dispatch(setToken(null));
                            store.dispatch(setRefreshToken(null));
                            store.dispatch(signOut());
                            processQueue(refreshError, null);
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
            }

            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            }).then((token) => {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return axiosInstance(originalRequest);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;